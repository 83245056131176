import './style.css'

interface TicketInfo {
  quantity: number,
  identifier: string
}

interface Options {
  loadingColor?: string;
}

let frameContainer: HTMLElement
let backdrop: HTMLElement
let loadingOverlay: HTMLElement
let popupWrapper: HTMLElement
let onClose: {
  reject: Function,
  resolve: Function
}

const handleIFrameMessage = (e: any): void => {
  if (!e.data) return
  switch (e.data.action) {
    case 'close':
      remove()
      break
    case 'initialized':
      console.log('initialized')
      popupWrapper.removeChild(loadingOverlay)
      break
    default:
  }
}

/**
 * Return a promise that rejects with a message.
 * @param msg Message to fail with.
 */
const failByPromise = (msg: string) => {
  return new Promise((resolve, reject) => reject(msg))
}

export const remove = (): void => {
  document.body.removeChild(backdrop)
  document.body.removeChild(popupWrapper)
  if (onClose) onClose.resolve()
}

/**
 * Buy given tickets in a shop hosted at the provided URL.
 * @param baseUrl URL the shop is provided under.
 * @param tickets An array of tickets to be purchased.
 * @param options Options options to configure behavior.
 */
export const buy = (baseUrl: string, tickets: TicketInfo[], options?: Options): Promise<any> => {
  if (!baseUrl) return failByPromise(`BaseUrl is required, was: ${baseUrl}`)
  if (tickets.length === 0) return failByPromise('Need to buy at least one ticket.')
  tickets.forEach(ticket => {
    if (!ticket.quantity) return failByPromise('Tickets require a quantity.')
    if (ticket.quantity < 1) return failByPromise(`Tickets require a quantity of at least 1. Was: ${ticket.quantity}`)
    if (!ticket.identifier) return failByPromise('Tickets require to be identified with an identifier.')
  })
  const products = encodeURI(JSON.stringify(tickets))
  show(`${baseUrl}/#/directcall?products=${products}`, options)
  return new Promise((resolve: Function, reject: Function) => onClose = {resolve, reject})
}

/**
 * Show the modal using a given URL.
 * @param url URL to show in modal.
 * @param options Options options to configure behavior.
 */
export const show = (url: string, options: Options = {}): void => {
  console.debug('Showing url: ', url)

  // Maybe we got some custom styles.
  if (options.loadingColor) {
    const style = document.createElement('style')
    style.innerHTML = `.loading-ring div { border-color: ${options.loadingColor} transparent transparent transparent; !important }`
    document.body.appendChild(style)
  }

  backdrop = document.createElement('div')
  backdrop.className = 'cubic-modal-backdrop'
  document.body.appendChild(backdrop)
  
  // A second wrapper needed to fix iOS Safari bugs (see css).
  popupWrapper = document.createElement('div')
  popupWrapper.className = 'cubic-modal-popup-wrapper'

  // Create container for iFrame.
  frameContainer = document.createElement('div')
  frameContainer.className = 'cubic-modal-frame-container'
  
  // Add close button to container.
  const close = document.createElement('a')
  close.className = 'cubic-modal-close-button'
  close.innerHTML = 'X'
  popupWrapper.appendChild(close)

  // Add iFrame
  const iFrame = document.createElement('iFrame')
  iFrame.className = 'cubic-modal-modal'
  iFrame.setAttribute('src', url)
  frameContainer.appendChild(iFrame)

  loadingOverlay = document.createElement('div')
  loadingOverlay.className = 'cubic-modal-loading-overlay'
  const loader = document.createElement('div')
  loader.className = 'cubic-modal-loading-ring'
  loader.innerHTML = '<div></div><div></div><div></div><div></div>'

  loadingOverlay.appendChild(loader)
  popupWrapper.appendChild(loadingOverlay)
 
  popupWrapper.appendChild(frameContainer)
  document.body.appendChild(popupWrapper)
  
  // Setup remove listeners.
  backdrop.onclick = remove
  close.onclick = remove
  
  // Listen for messages form the frame.
  window.onmessage = handleIFrameMessage
}
